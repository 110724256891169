import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import { Input } from './Input';

function App() {
  return (
    <div className="bg-[#000106] h-screen min-h-screen text-white fixed md:static overflow-hidden">
      <div className="bg-[url('./assets/bg-test.png')] bg-fix bg-no-repeat h-full bg-center flex flex-col items-center">
        <div className="darken-bg h-full"></div>
        <section className="content grow-[0.6] md:grow-[1] flex flex-col w-[98%] xl:w-[50%]  items-center justify-between">
          <div className="flex flex-col items-center">
            <div className="mt-4 w-[90%] 2k:w-[75%]  font-bold text-lg md:text-2xl flex justify-between">
              <p>Prism Break</p>
              <div className=" flex gap-8 items-center">
                <a
                  href="https://twitter.com/prismbreakgame"
                  target="_blank"
                  rel="noreferrer"
                  alt="Twitter"
                >
                  <img src={twitter} alt="twitter" className="social-icon" />
                </a>
                <a
                  href="https://discord.gg/2Rg8j3r2uY"
                  target="_blank"
                  rel="noreferrer"
                  alt="Join our discord!"
                >
                  <img src={discord} alt="discord" className="social-icon" />
                </a>
              </div>
            </div>
            <div className="mt-4 md:mt-14 flex flex-col items-center gap-2 w-[100%]  2xl:w-[80%] 3xl:w-[50%]">
              <div
                className="glow-text galaxy-fold:text-3xl text-5xl lg:text-7xl"
                data-text="JOIN THE FIGHT"
              >
                JOIN THE FIGHT
              </div>
              <div className=" box text-center text-sm md:text-base w-[90%] tracking-wider font">
                Join the spectrum of battle where color is your weapon—strategy
                meets vibrancy in the ultimate fight for survival. Be the first
                to forge your destiny in Prism Break's world of color-coded
                combat!
              </div>
            </div>
          </div>
        </section>
        <section className="footer w-[95%] md:mb-20 shrink-0 md:w-fit flex flex-col text-center gap-6 md:gap-8 justify-center items-center">
          <div className="md:text-lg">
            Enter your email to stay up to date with the latest news and updates
          </div>
          <div className="w-full md:w-[70%]">
            {/* <canvas
              id="email-btn-canvas"
              className="bg-red-500 w-full float-right fixed w-80 bottom-0 right-0 h-20"
            ></canvas> */}
            <Input />
          </div>
        </section>
      </div>
    </div>
  );
}

export default App;
