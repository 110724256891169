import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  addDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import confetti from 'canvas-confetti';

export const Input = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyChmkiisZ2zZr5qtXIQN8Noa1O7gscU2ps',
    authDomain: 'prism-break-eceb1.firebaseapp.com',
    projectId: 'prism-break-eceb1',
    storageBucket: 'prism-break-eceb1.appspot.com',
    messagingSenderId: '803510724777',
    appId: '1:803510724777:web:6edccef3638a7c761f61b8',
  };

  const app = initializeApp(firebaseConfig);

  const db = getFirestore(app);

  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const shootConfetti = () => {
    const colors = ['#1ff1cc', '#27df4d', '#f9d855', '#e82f35'];

    confetti({
      particleCount: 100,
      angle: 60,
      spread: 55,
      origin: { x: 0, y: 1 },
      colors: colors,
    });
    confetti({
      particleCount: 100,
      angle: 120,
      spread: 55,
      origin: { x: 1, y: 1 },
      colors: colors,
    });
  };

  const validateEmail = (e) => {
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setDisabled(false);
      setEmail(e.target.value);
    } else {
      setDisabled(true);
    }
  };

  const submitEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await addDoc(collection(db, 'newsletter'), {
        date: serverTimestamp(),
        email: email,
      });
      if (result) {
        shootConfetti();
        setMessage('Thank you for signing up!');
      }
    } catch (err) {
      console.log('ERROR: ', err);
      setError(true);
      setMessage('Error signing up!');
    }
  };

  return (
    <div className="relative text-black w-[100%]">
      {message ? (
        <div className={`${error ? 'text-red-500' : 'text-green-500'} text-xl`}>
          {message}
        </div>
      ) : (
        <>
          {loading ? (
            <div className="text-xl text-yellow-400">Loading...</div>
          ) : (
            <form onSubmit={(e) => submitEmail(e)}>
              <input
                type="email"
                className="h-12 w-[100%] pl-4 rounded-2xl z-0 focus:shadow focus:outline-none"
                placeholder="Email Address"
                required
                onChange={(e) => validateEmail(e)}
              />
              <div className="absolute top-0 right-0">
                <button
                  disabled={disabled}
                  className="h-12 px-6 text-white rounded-bl-none rounded-tl-none rounded-xl disabled:bg-[#c6b3b4] hover:bg-[#df4143] bg-[#fc494c]"
                >
                  Sign Up
                </button>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
};
